import { isPublic } from "config";
import { GetProfileResponse } from "proto/user/v1/user_api_pb";
import { useAuthUser } from "use-eazy-auth";

const _useUser = () => {
  if (isPublic) {
    return {
      user: new GetProfileResponse(),
      token: "",
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user, token } = useAuthUser<GetProfileResponse, string>();
  return { user, token };
};

export const useUser = () => {
  const { user } = _useUser();
  return user;
};

export const useUserToken = () => {
  const { token } = _useUser();
  return token ?? "";
};

export const useUserAndToken = () => {
  const { user, token } = _useUser();
  return { user, token };
};
