// @generated by protoc-gen-connect-es v0.8.4 with parameter "target=ts"
// @generated from file auth/v1/auth.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { LoginRequest, LoginResponse } from "./auth_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * AuthAPI service. Provides auth related services.
 *
 * @generated from service auth.v1.AuthAPI
 */
export const AuthAPI = {
  typeName: "auth.v1.AuthAPI",
  methods: {
    /**
     * Login allows users to login
     *
     * @generated from rpc auth.v1.AuthAPI.Login
     */
    login: {
      name: "Login",
      I: LoginRequest,
      O: LoginResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

