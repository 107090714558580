import { Home } from "Home";
import SignIn from "SignIn";
import { useAuthState } from "use-eazy-auth";

export const Screens = () => {
  const { authenticated, bootstrappedAuth } = useAuthState();
  if (!bootstrappedAuth) {
    return <div>Please wait, we are logging you in...</div>;
  }

  return authenticated ? <Home /> : <SignIn />;
};
