import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Circle,
  chakra,
  Progress,
  useColorModeValue,
  Text,
  Center,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";

export default function Header() {
  const progressBg = useColorModeValue("gray.100", "gray.700");
  const { activeStep } = useWizard();

  const getStep = (step: number, text: string) => {
    if (activeStep > step) {
      return (
        <Circle>
          <Circle bg="brand.500" minWidth={6} minHeight={6} mr="1">
            <CheckIcon fontSize={12} color="white" />
          </Circle>
          <Text color="brand.600">{text}</Text>
        </Circle>
      );
    } else if (activeStep === step) {
      return (
        <Circle>
          <Circle bg="brand.500" minWidth={6} minHeight={6} mr="1">
            <chakra.p color="white" fontSize={12}>
              {step + 1}
            </chakra.p>
          </Circle>
          <Text color="brand.600">{text}</Text>
        </Circle>
      );
    } else {
      return (
        <Circle>
          <Circle bg="gray.400" minWidth={6} minHeight={6} mr="1">
            <chakra.p color="white" fontSize={12}>
              {step + 1}
            </chakra.p>
          </Circle>
          <Text>{text}</Text>
        </Circle>
      );
    }
  };

  const Step1 = () => getStep(0, "Select Camera");
  const Step2 = () => getStep(1, "Select Court");
  const Step3 = () => getStep(2, "Verify");
  const Step4 = () => getStep(3, "Finish");

  return (
    <Center>
      <Box w={{ base: "90%", md: "50%" }} bg={progressBg} p={4} mb={2}>
        <SimpleGrid columns={5} spacingY={2}>
          <GridItem colSpan={2}>
            <Circle>
              <Step1 />
            </Circle>
          </GridItem>
          <GridItem colSpan={1}>
            <Center>
              <Progress
                h="4px"
                value={100}
                colorScheme={activeStep > 0 ? "brand" : "blackAlpha"}
                width="100%"
                m={3}
              />
            </Center>
          </GridItem>
          <GridItem colSpan={2}>
            <Circle>
              <Step2 />
            </Circle>
          </GridItem>
          <GridItem colSpan={2}>
            <Circle>
              <Step3 />
            </Circle>
          </GridItem>
          <GridItem colSpan={1} verticalAlign={"center"}>
            <Center>
              <Progress
                h="4px"
                value={100}
                colorScheme={activeStep > 2 ? "brand" : "blackAlpha"}
                width="100%"
                m={3}
              />
            </Center>
          </GridItem>
          <GridItem colSpan={2}>
            <Circle>
              <Step4 />
            </Circle>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Center>
  );
}
