import { useQuery } from "@tanstack/react-query";
import { listRecordingFrames } from "proto/recording/v1/recording_api-RecordingAPI_connectquery";
import { useUserToken } from "./useUser";

export const useGetFrames = (id: string) => {
  const token = useUserToken();
  const { isLoading, isFetching, isError, error, data } = useQuery(
    listRecordingFrames.useQuery(
      { recordingId: id },
      {
        callOptions: {
          headers: { Authorization: token },
        },
      }
    )
  );
  return { isLoading, isFetching, isError, error, data };
};
