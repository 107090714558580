import { intervalToDuration } from "date-fns";
import {
  ExportState,
  MatchType,
  Player,
  PracticeInfo,
  PracticeType,
  RecordingInfo,
  RecordingState,
  ShotDirection,
  ShotType,
} from "proto/recording/v1/recording_api_pb";
import { Box, Location } from "proto/base/v1/location_pb";
import { VideoQuality } from "proto/base/v1/media_pb";
import { User } from "proto/base/v1/user_pb";
import { StreamState } from "proto/stream/v1/stream_api_pb";

export const protoUserName = (user?: User): string => {
  return `${user?.firstName} ${user?.lastName}`;
};

export function protoRecordingStateString(state: RecordingState): string {
  switch (state) {
    case RecordingState.INVALID:
      return "Invalid";

    case RecordingState.READY:
      return "Ready";

    case RecordingState.DETECTING:
      return "Detecting";

    case RecordingState.PRE_PROCESSING:
      return "Pre-Processing";

    case RecordingState.PROCESSING:
      return "Processing";

    case RecordingState.PROCESSED:
      return "Processed";
  }

  return "";
}

export function protoExportStateString(state: ExportState): string {
  switch (state) {
    case ExportState.INVALID:
      return "Invalid";

    case ExportState.STARTED:
      return "Exporting";

    case ExportState.COMPLETED:
      return "Exported";
  }
}

export function protoVideoQualityString(quality: VideoQuality): string {
  switch (quality) {
    case VideoQuality.VIDEO_QUALITY_1080P:
      return "1080P";

    case VideoQuality.VIDEO_QUALITY_720P:
      return "720P";

    case VideoQuality.VIDEO_QUALITY_INVALID:
      return "Unkown";
  }
}

export function protoRecordingCompletionPercentageString(
  frames = 1,
  framesProcessed = 0
): string {
  const percentage = ((framesProcessed ?? 0) / (frames ?? 1)) * 100;
  return (percentage < 100 ? percentage : 100).toFixed(0);
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const toDataURL = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const getRoutePath = (url: string) => {
  return `${process.env.PUBLIC_URL}${url}`;
};

export const roundNumber = (num = 0, extraDivide = 1) => {
  return Math.round((num / extraDivide) * 100) / 100;
};

export const addTimeStampDurations = (
  timeStamps: { start: Date; end: Date }[]
) => {
  const dur: Duration = {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  timeStamps.forEach(({ start, end }) => {
    const curDur = intervalToDuration({
      start,
      end,
    });

    dur.years = (dur.years ?? 0) + (curDur.years ? curDur.years : 0);
    dur.months = (dur.months ?? 0) + (curDur.months ? curDur.months : 0);
    dur.weeks = (dur.weeks ?? 0) + (curDur.weeks ? curDur.weeks : 0);
    dur.days = (dur.days ?? 0) + (curDur.days ? curDur.days : 0);
    dur.hours = (dur.hours ?? 0) + (curDur.hours ? curDur.hours : 0);
    dur.minutes = (dur.minutes ?? 0) + (curDur.minutes ? curDur.minutes : 0);
    dur.seconds = (dur.seconds ?? 0) + (curDur.seconds ? curDur.seconds : 0);
  });

  return dur;
};

export const toHHMMSS = (seconds: number, preserveMilli: boolean = false) => {
  const date = new Date(seconds * 1000);
  if (seconds < 60 * 60) {
    return date.toISOString().substr(14, preserveMilli ? 8 : 5);
  }
  return date.toISOString().substr(11, 8);
};

export const toHHMMSSss = (seconds: number) => {
  const date = new Date(seconds * 1000);
  return date.toISOString().substr(11, 12);
};

export const toHHMMSSLeft = ({
  completed,
  total,
}: {
  total: number;
  completed: number;
}) => {
  const totalDate = new Date(total * 1000);
  const completedDate = new Date(completed * 1000);
  if (total < 60 * 60) {
    return `${completedDate.toISOString().substr(14, 5)} / ${totalDate
      .toISOString()
      .substr(14, 5)}`;
  }
  return `${completedDate.toISOString().substr(11, 8)} / ${totalDate
    .toISOString()
    .substr(11, 8)}`;
};

export const normaliseNumber = (v?: number) => {
  if (!v || isNaN(v)) return 0;

  return v;
};

export const normaliseStringToNumber = (v?: string) => {
  if (!v) return 0;
  return normaliseNumber(Number.parseInt(v));
};

export const range = (min = 1, max = 1) =>
  Array.from({ length: max - min + 1 }, (_, i) => min + i);

export function protoShotTypeString(type: ShotType): string {
  switch (type) {
    case ShotType.INVALID:
      return "";
    case ShotType.TOSS:
      return "Clear";
    case ShotType.DROP:
      return "Dropshot";
    case ShotType.SMASH:
      return "Smash";
    case ShotType.LIFT:
      return "Lift";
    case ShotType.BLOCK:
      return "Net";
    case ShotType.DRIVE:
      return "Drive";
    case ShotType.PUSH:
      return "Push";
    case ShotType.WIN:
      return "Win";
    case ShotType.ERROR:
      return "Error";
  }
}

export function protoShotTypeFromString(s: string): ShotType {
  switch (s) {
    case "":
      return ShotType.INVALID;
    case "Clear":
      return ShotType.TOSS;
    case "Dropshot":
      return ShotType.DROP;
    case "Smash":
      return ShotType.SMASH;
    case "Lift":
      return ShotType.LIFT;
    case "Net":
      return ShotType.BLOCK;
    case "Drive":
      return ShotType.DRIVE;
    case "Push":
      return ShotType.PUSH;
    case "Win":
      return ShotType.WIN;
    case "Error":
      return ShotType.ERROR;
  }
  return ShotType.INVALID;
}

export function protoShotDirectionString(direction: ShotDirection): string {
  switch (direction) {
    case ShotDirection.INVALID:
      return "";
    case ShotDirection.CROSS:
      return "Cross";
    case ShotDirection.STRAIGHT:
      return "Straight";
    case ShotDirection.AGNOSTIC:
      return "";
  }
}

export function protoShotPatternString(
  type: ShotType,
  direction: ShotDirection
): string {
  const shotType = protoShotTypeString(type);
  const shotDirection = protoShotDirectionString(direction);

  if (shotType.length < 1) {
    return shotDirection;
  } else if (shotDirection.length < 1) {
    return shotType;
  }

  return `${shotDirection} ${shotType}`;
}

export function protoMatchTypeString(matchType: MatchType): string {
  switch (matchType) {
    case MatchType.INVALID:
      return "";
    case MatchType.PRACTICE:
      return "Practice";
    case MatchType.QUARTER_FINAL:
      return "Quarter Final";
    case MatchType.SEMI_FINAL:
      return "Semi Final";
    case MatchType.FINAL:
      return "Final";
    case MatchType.PRIVATE:
      return "Private";
  }
}

export function allMatchTypeStrings(): string[] {
  return ["Practice", "Quarter Final", "Semi Final", "Final", "Private"];
}

export function stringMatchTypeToProto(matchType: string): MatchType {
  switch (matchType) {
    case "":
      return MatchType.INVALID;
    case "Practice":
      return MatchType.PRACTICE;
    case "Quarter Final":
      return MatchType.QUARTER_FINAL;
    case "Semi Final":
      return MatchType.SEMI_FINAL;
    case "Final":
      return MatchType.FINAL;
    case "Private":
      return MatchType.PRIVATE;
  }
  return MatchType.INVALID;
}

export function shortName(s: string): string {
  const splits = s.split(" ");
  if (splits.length > 1) {
    return splits[0] + " " + splits[1][0];
  }
  return s;
}

export function isStringEqual(s1?: string, s2?: string): boolean {
  if (!s1 || !s2) {
    return false;
  }

  s1 = s1.trim().toLowerCase();
  s2 = s2.trim().toLowerCase();

  return s1 === s2;
}

export function stringPlayerToProto(s: string): Player {
  switch (s) {
    case "":
      return Player.INVALID;
    case "Player 1":
      return Player.P1;
    case "Player 2":
      return Player.P2;
  }
  return Player.INVALID;
}

export function protoPlayerToString(v: Player): string {
  switch (v) {
    case Player.P1:
      return "Player 1";
    case Player.P2:
      return "Player 2";
  }
  return "";
}

export const scaleBox = (
  b?: Box,
  info?: RecordingInfo | PracticeInfo,
  h?: number,
  w?: number
): Box => {
  const res: Box = new Box({
    topLeft: scaleLocation(b?.topLeft, info, h, w),
    topRight: scaleLocation(b?.topRight, info, h, w),
    bottomLeft: scaleLocation(b?.bottomLeft, info, h, w),
    bottomRight: scaleLocation(b?.bottomRight, info, h, w),
    middle: scaleLocation(b?.middle, info, h, w),
    middleLeft: scaleLocation(b?.middleLeft, info, h, w),
    middleRight: scaleLocation(b?.middleRight, info, h, w),
    middleTop: scaleLocation(b?.middleTop, info, h, w),
    middleBottom: scaleLocation(b?.middleBottom, info, h, w),
  });
  return res;
};

export const scaleLocation = (
  l?: Location,
  info?: RecordingInfo | PracticeInfo,
  h?: number,
  w?: number
): Location => {
  return new Location({
    x: ((l?.x ?? 0) * (w ?? 1)) / (info?.frameWidth ?? 1),
    y: ((l?.y ?? 0) * (h ?? 1)) / (info?.frameHeight ?? 1),
  });
};

export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export function protoPracticeTypeString(v?: PracticeType): string {
  switch (v) {
    case PracticeType.SHADOW_DRILL:
      return "Shadow Drill";
  }

  return "";
}

export function parseProtoPracticeTypeFromString(v: string): PracticeType {
  switch (v) {
    case protoPracticeTypeString(PracticeType.SHADOW_DRILL):
      return PracticeType.SHADOW_DRILL;
  }

  return PracticeType.INVALID;
}

export function allPracticeTypeStrings(): string[] {
  return [protoPracticeTypeString(PracticeType.SHADOW_DRILL)];
}

export function protoStreamStateString(state: StreamState): string {
  switch (state) {
    case StreamState.READY:
      return "Ready";

    case StreamState.ACTIVE:
      return "Active";

    case StreamState.PAUSED:
      return "Paused";

    case StreamState.DONE:
      return "Done";
  }
  return "";
}
