import { createPromiseClient } from "@bufbuild/connect";
import { createConnectTransport } from "@bufbuild/connect-web";
import { AuthAPI } from "proto/auth/v1/auth_connect";
import { RecordingAPI } from "proto/recording/v1/recording_api_connect";
import { SettingAPI } from "proto/setting/v1/setting_api_connect";
import { UserAPI } from "proto/user/v1/user_api_connect";
import { apiPath } from "server-props";

export const transport = createConnectTransport({
  baseUrl: apiPath,
});

export const recordingAPIClient = createPromiseClient(RecordingAPI, transport);
export const settingAPIClient = createPromiseClient(SettingAPI, transport);
export const userAPIClient = createPromiseClient(UserAPI, transport);
export const authAPIClient = createPromiseClient(AuthAPI, transport);
