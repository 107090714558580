import { BrowserRouter } from "react-router-dom";
import Auth from "use-eazy-auth";
import { GetProfileResponse } from "proto/user/v1/user_api_pb";
import { LoginRequest } from "proto/auth/v1/auth_pb";
import { authAPIClient, userAPIClient } from "clients";
import { Screens } from "Screens";
import { isPublic } from "config";
import { Home } from "Home";

const meCall = async (accessToken: string) => {
  return userAPIClient.getProfile(
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
};

const loginCall = async (req: LoginRequest) => {
  const resp = await authAPIClient.login(req);
  return {
    accessToken: resp.token,
  };
};

export const Main = () => {
  if (isPublic) {
    return (
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Auth<string, string, GetProfileResponse, LoginRequest>
        meCall={meCall}
        loginCall={loginCall}
        storageNamespace="reticulo-studio-admin-auth"
      >
        <Screens />
      </Auth>
    </BrowserRouter>
  );
};
