import { useQuery } from "@tanstack/react-query";
import { useStateContext } from "./state";
import { Flex, HStack, Stack, Text } from "@chakra-ui/layout";
import { Loading } from "components";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { useWizard } from "react-use-wizard";
import { createCameraVerificationFrame } from "proto/recording/v1/recording_api-RecordingAPI_connectquery";
import { useUserToken, useWindowSize } from "hooks";
import { Center } from "@chakra-ui/react";

export const useGetVerificationFrame = () => {
  const { state, capturedImage, camera } = useStateContext();
  const token = useUserToken();
  const { isLoading, isFetching, isError, error, data } = useQuery(
    createCameraVerificationFrame.useQuery(
      {
        image: capturedImage,
        court: state.request.court,
        camera,
      },
      {
        callOptions: {
          headers: { Authorization: token },
        },
      }
    )
  );

  return { isLoading, isFetching, isError, error, data };
};

export default function Verification() {
  const { isLoading, isFetching, isError, error, data } =
    useGetVerificationFrame();
  const { nextStep, previousStep } = useWizard();
  const { height } = useWindowSize();

  if (isLoading || isFetching) return <Loading />;

  if (isError)
    return (
      <Text
        as="h3"
        py={2}
        textAlign="center"
        fontWeight="bold"
        textTransform="uppercase"
        color="red.500"
        letterSpacing={1}
      >
        An error has occurred: {error?.message}{" "}
      </Text>
    );

  return (
    <Stack>
      <Center pt={4}>
        <HStack maxW={"50vw"}>
          <Button
            width="100%"
            onClick={() => {
              previousStep();
            }}
            variant="outline"
          >
            Go Back
          </Button>
          <Button
            mb={2}
            width="100%"
            onClick={() => {
              nextStep();
            }}
          >
            Next
          </Button>
        </HStack>
      </Center>
      <Center pt={4}>
        <Flex display="block" h={height / 2} w={(height * 1920) / 1080 / 2}>
          <Image zIndex={-1} src={data?.image} alt={""} roundedTop="lg" />
        </Flex>
      </Center>
    </Stack>
  );
}
