import constate from "constate";
import { useMemo, useState } from "react";
import {
  GeneralStatistics,
  HitStatistics,
  MatchType,
  Recording,
  Report,
  Statistics,
} from "proto/recording/v1/recording_api_pb";
import { Tournament } from "proto/tournament/v1/tournament_api_pb";

interface MatchGameStatsAndReport {
  p1GeneralStatistics?: GeneralStatistics;
  p2GeneralStatistics?: GeneralStatistics;
  p1HitStatistics?: HitStatistics;
  p2HitStatistics?: HitStatistics;
  p1MatchOrGameReport?: Report;
  p2MatchOrGameReport?: Report;
}

const useMainState = (props: {
  recording: Recording;
  tournaments: Tournament[];
}) => {
  const [recording, setRecording] = useState(props.recording);
  const [currPlayer, setCurrPlayer] = useState<"p1" | "p2" | "combined">("p1");
  const [searchTerm, setSearchTerm] = useState("");
  const [gameStatSelection, setGameStatSelection] = useState<{
    game: number;
    part: number;
  }>({ game: 0, part: 0 });
  const generalHitStatsAndReport: MatchGameStatsAndReport = useMemo(() => {
    if (!recording) {
      return {};
    }
    const games = recording?.setting?.match?.games ?? [];
    const p1 = recording?.p1MatchStatistics;
    const p2 = recording?.p2MatchStatistics;
    if (gameStatSelection.game > 0) {
      const gameIdx = gameStatSelection.game - 1;
      var p1GameStats: Statistics | undefined = undefined;
      var p2GameStats: Statistics | undefined = undefined;
      var p1GameReport: Report | undefined = undefined;
      var p2GameReport: Report | undefined = undefined;
      if (gameStatSelection.part === 1) {
        p1GameStats = games[gameIdx]?.g1?.p1GameStatistics;
        p2GameStats = games[gameIdx]?.g1?.p2GameStatistics;
        p1GameReport = games[gameIdx]?.g1?.p1GameReport;
        p2GameReport = games[gameIdx]?.g1?.p2GameReport;
      } else if (gameStatSelection.part === 2) {
        p1GameStats = games[gameIdx]?.g2?.p1GameStatistics;
        p2GameStats = games[gameIdx]?.g2?.p2GameStatistics;
        p1GameReport = games[gameIdx]?.g2?.p1GameReport;
        p2GameReport = games[gameIdx]?.g2?.p2GameReport;
      } else {
        p1GameStats = games[gameIdx].p1GameStatistics;
        p2GameStats = games[gameIdx].p2GameStatistics;
        p1GameReport = games[gameIdx].p1GameReport;
        p2GameReport = games[gameIdx].p2GameReport;
      }

      return {
        p1GeneralStatistics: p1GameStats?.generalStatistics,
        p2GeneralStatistics: p2GameStats?.generalStatistics,
        p1HitStatistics: p1GameStats?.hitStatistics,
        p2HitStatistics: p2GameStats?.hitStatistics,
        p1MatchOrGameReport: p1GameReport,
        p2MatchOrGameReport: p2GameReport,
      };
    }

    return {
      p1GeneralStatistics: p1?.generalStatistics,
      p2GeneralStatistics: p2?.generalStatistics,
      p1HitStatistics: p1?.hitStatistics,
      p2HitStatistics: p2?.hitStatistics,
      p1MatchOrGameReport: recording.p1MatchReport,
      p2MatchOrGameReport: recording.p2MatchReport,
    };
  }, [recording, gameStatSelection]);

  const updateRecordingName = (n: string) => {
    var info = recording.info!;
    if (info) {
      info!.name = n;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  const updateMatchType = (mt: MatchType) => {
    var info = recording.info!;
    if (info) {
      info!.matchType = mt;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  const updatePassword = (password: string) => {
    var info = recording.info!;
    if (info) {
      info!.password = password;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  const updateScore = (score: string) => {
    var info = recording.info!;
    if (info) {
      info!.score = score;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  const updateTag = (tag: string) => {
    var info = recording.info!;
    if (info) {
      info!.tag = tag;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  const updateTournament = (id: string) => {
    var info = recording.info!;
    if (info) {
      info!.tournamentId = id;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  const updateTournamentStageKey = (stageKey: string) => {
    var info = recording.info!;
    if (info) {
      info!.tournamentStageKey = stageKey;
    }
    const t = recording.clone();
    t.info = info;
    setRecording(t);
  };

  return {
    recording,
    currPlayer,
    setCurrPlayer,
    searchTerm,
    setSearchTerm,
    updateRecordingName,
    updateMatchType,
    updatePassword,
    updateScore,
    updateTag,
    gameStatSelection,
    setGameStatSelection,
    generalHitStatsAndReport,
    tournaments: props.tournaments,
    updateTournament,
    updateTournamentStageKey,
  };
};

const [StateProvider, useStateContext] = constate(useMainState);

export { StateProvider, useStateContext };
