import { isPublic } from "config";
import { useAuthActions } from "use-eazy-auth";

export const useLogout = () => {
  if (isPublic) {
    return () => {};
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { logout } = useAuthActions();
  return logout;
};
