// @generated by protoc-gen-connect-query v0.2.0 with parameter "target=ts"
// @generated from file setting/v1/setting_api.proto (package setting.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { createQueryService } from "@bufbuild/connect-query";
import { MethodKind } from "@bufbuild/protobuf";
import { AddCameraRequest, AddCameraResponse, DeleteCameraRequest, DeleteCameraResponse, GetSettingRequest, GetSettingResponse, ListCamerasRequest, ListCamerasResponse, UnLockCameraRequest, UnLockCameraResponse, UpdateSettingRequest, UpdateSettingResponse } from "./setting_api_pb.js";

export const typeName = "setting.v1.SettingAPI";

/**
 * @generated from rpc setting.v1.SettingAPI.GetSetting
 */
export const getSetting = createQueryService({
  service: {
    methods: {
      getSetting: {
        name: "GetSetting",
        kind: MethodKind.Unary,
        I: GetSettingRequest,
        O: GetSettingResponse,
      },
    },
    typeName: "setting.v1.SettingAPI",
  },
}).getSetting;

/**
 * @generated from rpc setting.v1.SettingAPI.UpdateSetting
 */
export const updateSetting = createQueryService({
  service: {
    methods: {
      updateSetting: {
        name: "UpdateSetting",
        kind: MethodKind.Unary,
        I: UpdateSettingRequest,
        O: UpdateSettingResponse,
      },
    },
    typeName: "setting.v1.SettingAPI",
  },
}).updateSetting;

/**
 * @generated from rpc setting.v1.SettingAPI.ListCameras
 */
export const listCameras = createQueryService({
  service: {
    methods: {
      listCameras: {
        name: "ListCameras",
        kind: MethodKind.Unary,
        I: ListCamerasRequest,
        O: ListCamerasResponse,
      },
    },
    typeName: "setting.v1.SettingAPI",
  },
}).listCameras;

/**
 * @generated from rpc setting.v1.SettingAPI.AddCamera
 */
export const addCamera = createQueryService({
  service: {
    methods: {
      addCamera: {
        name: "AddCamera",
        kind: MethodKind.Unary,
        I: AddCameraRequest,
        O: AddCameraResponse,
      },
    },
    typeName: "setting.v1.SettingAPI",
  },
}).addCamera;

/**
 * @generated from rpc setting.v1.SettingAPI.DeleteCamera
 */
export const deleteCamera = createQueryService({
  service: {
    methods: {
      deleteCamera: {
        name: "DeleteCamera",
        kind: MethodKind.Unary,
        I: DeleteCameraRequest,
        O: DeleteCameraResponse,
      },
    },
    typeName: "setting.v1.SettingAPI",
  },
}).deleteCamera;

/**
 * @generated from rpc setting.v1.SettingAPI.UnLockCamera
 */
export const unLockCamera = createQueryService({
  service: {
    methods: {
      unLockCamera: {
        name: "UnLockCamera",
        kind: MethodKind.Unary,
        I: UnLockCameraRequest,
        O: UnLockCameraResponse,
      },
    },
    typeName: "setting.v1.SettingAPI",
  },
}).unLockCamera;
