// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file auth/v1/auth.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message auth.v1.LoginRequest
 */
export const LoginRequest = proto3.makeMessageType(
  "auth.v1.LoginRequest",
  () => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.v1.LoginResponse
 */
export const LoginResponse = proto3.makeMessageType(
  "auth.v1.LoginResponse",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

