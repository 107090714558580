import { useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAuthActions, useAuthState } from "use-eazy-auth";
import { LoginRequest, LoginResponse } from "proto/auth/v1/auth_pb";

type SignInForm = {
  email: string;
  password: string;
};

export default function SignIn() {
  const { loginLoading, loginError } = useAuthState();
  const { login, clearLoginError } = useAuthActions<
    string,
    string,
    LoginResponse,
    LoginRequest
  >();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInForm>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const onSubmit = (data: SignInForm) => {
    login(new LoginRequest({ username: data.email, password: data.password }));
  };
  const toast = useToast();

  // clear login error on unmount
  useEffect(() => () => clearLoginError(), [clearLoginError]);

  // clear login error when username or password changes
  useEffect(() => {
    clearLoginError();
  }, [clearLoginError]);

  useEffect(() => {
    if (loginError) {
      toast({
        title: "Invalid Credentials.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        h="100vh"
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign in to your account</Heading>
            {/* <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text> */}
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={!!errors?.email}>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  {...register("email", {
                    required: "Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid Email",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={!!errors?.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Required",
                    minLength: {
                      value: 1,
                      message: "Required",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors?.password && errors?.password?.message}
                </FormErrorMessage>
              </FormControl>
              <Stack spacing={2}>
                <Button isLoading={isSubmitting || loginLoading} type="submit">
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </form>
  );
}
