import {
  chakra,
  Icon,
  Flex,
  Image,
  Button,
  Stack,
  Center,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { useState } from "react";
import { useWizard } from "react-use-wizard";
import { useStateContext } from "./state";
import { Loading } from "components";
import { useUserToken, useWindowSize } from "hooks";
import { CaptureCameraFrameRequest } from "proto/recording/v1/recording_api_pb";
import { useMutation } from "@tanstack/react-query";
import { captureCameraFrame } from "proto/recording/v1/recording_api-RecordingAPI_connectquery";

interface Point {
  x: number;
  y: number;
  xRel: number;
  yRel: number;
}

export default function CourtSelector() {
  const [points, setPoints] = useState<Point[]>([]);
  const { setCourt, setCapturedImage, capturedImage, camera } =
    useStateContext();
  const { nextStep, previousStep } = useWizard();

  const { height } = useWindowSize();

  const token = useUserToken();
  const { mutate, isLoading: mutationLoading } = useMutation({
    ...captureCameraFrame.useMutation({
      callOptions: {
        headers: { Authorization: token },
      },
    }),
    onSuccess: (resp) => {
      setCapturedImage(resp.image);
    },
  });

  if (mutationLoading) return <Loading text="Saving..." />;

  let msg = "";

  switch (points.length) {
    case 0:
      msg = "Select Top Left Point";
      break;
    case 1:
      msg = "Select Top Right Point";
      break;
    case 2:
      msg = "Select Bottom Right Point";
      break;
    case 3:
      msg = "Select Bottom Left Point";
      break;
    case 4:
      msg = `Select Middle Left Point`;
      break;
    case 5:
      msg = `Select Middle Right Point`;
      break;
    case 6:
      msg = `Done! Click "Select" to proceed`;
      break;

    default:
      break;
  }

  return (
    <Stack>
      <SimpleGrid columns={{ base: 2, md: 5 }} spacing={4} p={4}>
        <Button onClick={() => previousStep()} colorScheme="orange">
          Go Back
        </Button>
        <Button
          width={"100%"}
          onClick={() => {
            var tlX = points[0].xRel,
              tlY = points[0].yRel,
              trX = points[1].xRel,
              trY = points[1].yRel,
              brX = points[2].xRel,
              brY = points[2].yRel,
              blX = points[3].xRel,
              blY = points[3].yRel,
              // m = (points[3].yRel - points[0].yRel) / 2 + points[0].yRel;
              mlY = points[4].yRel,
              mrY = points[5].yRel;

            setCourt(tlX, tlY, trX, trY, blX, blY, brX, brY, mlY, mrY);
            nextStep();
          }}
          disabled={points.length !== 6}
        >
          Select
        </Button>
        <Button
          width={"100%"}
          onClick={() => {
            setPoints([]);
          }}
          colorScheme="red"
        >
          Clear
        </Button>
        <Button
          width={"100%"}
          isDisabled={points.length < 1}
          onClick={() => {
            points.pop();
            setPoints([...points]);
          }}
          colorScheme="blue"
        >
          Undo
        </Button>
        <Button
          width={"100%"}
          colorScheme="green"
          onClick={() => {
            mutate(new CaptureCameraFrameRequest({ camera }));
          }}
        >
          New Court Image
        </Button>
      </SimpleGrid>

      <Center>
        <Text>{msg}</Text>
      </Center>
      <Center cursor={points.length < 6 ? undefined : "not-allowed"}>
        <Flex
          onMouseDown={(e) => {
            if (points.length >= 6) {
              return;
            }
            /* es-lint-disable */
            var rect = (e.target as HTMLElement).getBoundingClientRect();
            var x = e.clientX; //x position within the element.
            var y = e.clientY; //y position within the element.
            points.push({
              x,
              y,
              xRel: (x - rect.left) / rect.width,
              yRel: (y - rect.top) / rect.height,
            });
            setPoints([...points]);
          }}
          display="block"
          h={height / 2}
          w={(height * 1920) / 1080 / 2}
        >
          <Image zIndex={-1} src={capturedImage} alt={""} />
          {points.map(({ x, y, xRel, yRel }) => (
            <chakra.div
              position="absolute" /* left={x - 10} top={y - 20} */
              left={x - 10}
              top={y - 20}
              key={`${x}-${y}]`}
            >
              <Icon viewBox="0 0 200 200" color="tomato">
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
            </chakra.div>
          ))}
        </Flex>
      </Center>
    </Stack>
  );
}
