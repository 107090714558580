import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, GridItem, Heading, Image, SimpleGrid } from "@chakra-ui/react";
import { BsFillForwardFill } from "react-icons/bs";
import OneVOne from './1v1.png'
import Streaming from './streaming.jpg'
import Practice from './practice-.png'
import { useNavigate } from "react-router-dom";

export default function StartLivePage() {
    const navigate = useNavigate()
    return (
        <>
            <Box mt={[5, 0]}>
                <Center>
                    <SimpleGrid columns={{ base: 1, md: 3 }} w='90%' spacing={4}>
                        <GridItem>
                            <Card>
                                <CardHeader>
                                    <Heading size={'2xl'}>Match Analysis</Heading>
                                </CardHeader>
                                <CardBody>
                                    <Center>
                                        <Image src={OneVOne} h={'20vh'}></Image>
                                    </Center>
                                    Analyse your match from Live Recording in Real Time
                                </CardBody>
                                <CardFooter>
                                    <Button w='full' onClick={() => {
                                        navigate("/start-match");
                                    }}>
                                        Start Match Analysis <BsFillForwardFill style={{ marginLeft: 8 }} />
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card>
                                <CardHeader>
                                    <Heading size={'2xl'}>Streaming</Heading>
                                </CardHeader>
                                <CardBody>
                                    <Center>
                                        <Image src={Streaming} h={'20vh'}></Image>
                                    </Center>
                                    Stream videos
                                </CardBody>
                                <CardFooter>
                                    <Button w='full' onClick={() => {
                                        navigate("/start-stream");
                                    }}>
                                        Start Stream <BsFillForwardFill style={{ marginLeft: 8 }} />
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card pointerEvents={'none'} opacity={0.5}>
                                <CardHeader>
                                    <Heading size={'2xl'}>Practice Sessions</Heading>
                                </CardHeader>
                                <CardBody>
                                    <Center>
                                        <Image src={Practice} h={'20vh'}></Image>
                                    </Center>
                                    Coming Soon
                                </CardBody>
                                <CardFooter>
                                    <Button w='full'>
                                        Start Practice <BsFillForwardFill style={{ marginLeft: 8 }} />
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </SimpleGrid>
                </Center>
            </Box>
        </>
    )
}