import { chakra, Container } from "@chakra-ui/react";
import { Loading } from "components";
import { StateProvider } from "hooks/provider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "./layout";
import ControlLiveRecording from "recording/pages/processedRecording/ControlLive";
import StartLivePage from "startLivePage";
import LiveRecording from "./recording/pages/startLiveRecording";
import { isPublic } from "config";

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

const ProfilePage = React.lazy(() => import("./profile/pages/ProfilePage"));
const RecordingsPage = React.lazy(
  () => import("./recording/pages/RecordingsPage")
);
const NewRecordingPage = React.lazy(
  () => import("./recording/pages/NewRecordingPage")
);
const RecordingPage = React.lazy(
  () => import("./recording/pages/RecordingPage")
);

const StreamsPage = React.lazy(() => import("./stream/pages/StreamsPage"));
const NewStreamPage = React.lazy(() => import("./stream/pages/NewStreamPage"));
const StreamPage = React.lazy(() => import("./stream/pages/StreamPage"));

const MainDashboardPage = React.lazy(
  () => import("./dashboard/pages/MainDashboardPage")
);

const TournamentsPage = React.lazy(
  () => import("./tournament/pages/TournamentsPage")
);
const TournamentPage = React.lazy(
  () => import("./tournament/pages/TournamentPage")
);
const NewTournamentPage = React.lazy(
  () => import("./tournament/pages/NewTournamentPage")
);

export const Home = () => {
  return (
    <StateProvider>
      <MainLayout>
        <chakra.div>
          <Routes>
            {!isPublic && (
              <Route
                path="/recordings"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <RecordingsPage />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/new-recording"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <NewRecordingPage />
                  </React.Suspense>
                }
              />
            )}
            <Route
              path="/recording/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RecordingPage />
                </React.Suspense>
              }
            />
            <Route
              path="/recording/:id/*"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RecordingPage />
                </React.Suspense>
              }
            />
            {!isPublic && (
              <Route
                path="/start-live"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <StartLivePage />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/profile"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <ProfilePage />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/control-live"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <ControlLiveRecording />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/streams"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <StreamsPage />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/start-stream"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <NewStreamPage />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/start-match"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <LiveRecording />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/stream/:id"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <StreamPage />
                  </React.Suspense>
                }
              />
            )}
            {!isPublic && (
              <Route
                path="/stream/:id/*"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <StreamPage />
                  </React.Suspense>
                }
              />
            )}
            <Route
              path={isPublic ? "/" : "/tournaments"}
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <TournamentsPage />
                </React.Suspense>
              }
            />
            {!isPublic && (
              <Route
                path="/new-tournament"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <NewTournamentPage />
                  </React.Suspense>
                }
              />
            )}
            <Route
              path="/tournament/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <TournamentPage />
                </React.Suspense>
              }
            />
            {!isPublic && (
              <Route
                path="/"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <MainDashboardPage />
                  </React.Suspense>
                }
              />
            )}
          </Routes>
        </chakra.div>
      </MainLayout>
    </StateProvider>
  );
};
